@import './../../variables';

.paymentTermsContainer{
  margin: 2% 5%;
  border-top: 20px solid #56aa1c;

  h4{
    text-align: center;
    font-size: 24px;
    padding-bottom: 60px;
    padding-top: 15px;
  }
  @media screen and (max-width: $menuChangedPx) {
    table{
      tr{
        td{
          display: flex;
          max-width: 100%;
        }
        td:nth-of-type(2){
          display: block;
        }

        td:nth-of-type(3){
          display: block;
        }
      }
    }
  }
}

.paperSquare{
  border-radius: 0;
}