@import '../../variables.scss';

.app-profile-form-inner {
    max-width: 512px;
    width: 512px;

    @media screen and (max-width: $xl) {
        margin: 0 auto;

        + .app-form-control-actions {
            margin: 0 auto;
        }
    }

    @media screen and (max-width:$zoomApplied) {
        max-width: 100%;
    }
}

@media screen and (max-width:800px) {
    .app-profile-form-inner .app-form-control {
        .app-form-control-input {
            input.app-form-input {
                font-size: 16px;
            }
        }

        .app-form-control-label {
            font-size: 17px;
        }
    }
}
