@import '../../variables';

$qrBorderDivSize: 60px;
$qrBorderBorderSize: 8px;

.app-signup-installer {
  .app-signup-installer-mobile-image {
    img{
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: $zindex-dropdown;
      transform: translateX(-50%);
      max-height: 450px;
      width: 417px;
    }
  }
  .app-signup-installer-description-container{
    background-image: url("./../../assets/Entry Monitor sign in image.png");
  }
  .app-signup-installer-description {
    backdrop-filter: blur(7px);
    background-color: hexToRGBA(#3F3E3E, 0.68);
    height: 100%;
    width: 100%;
    color: white;
    font-size: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .app-signup-installer-description-text{
      max-width: 668px;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
      }

      li::before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        background: $primary-green-color;
        border-radius: 50%;
        transform: translateX(-9px);
      }
    }
  }

  .app-signup-installer-body {
    margin: 0 auto;
    padding: 0 160px;
  }

  .app-signup-qr-code {
    width: 40%;
    height: 40%;
    position: relative;
    padding-top: 10px;
    box-sizing: border-box;
    text-align: center;

    .app-signup-qr-code-image {
      width: 80%;
      height: 75%;
      min-width: 155px;
      min-height: 151px;
      margin: 0 auto;

      padding: 5px;
      border: 6px solid $primary-green-color; /* the thickness and color */
      -webkit-mask:
              conic-gradient(at 40px 40px,#0000 75%,#000 0)
              0 0/calc(100% - 40px) calc(100% - 40px),
              linear-gradient(#000 0 0, $primary-green-color) content-box;
      img {
        width: 100%;
      }
    }

    .app-signup-qr-code-helper {
      position: absolute;
      right: -168px;
      top: -1%;
      display: flex;
      flex-direction: column;

      span {
        display: block;
        position: absolute;
        right: -17px;
        top: 31px;
        font-family: 'Segoe Script', myriad-pro, serif;
        font-size: 28px;
        transform: rotate(-11deg);
      }
    }
  }

  .app-signup-installer-back-button-container {
    width: 238px;
    text-align: center;
  }
}

.app-fake-search {
  background-color: $lighter-gray-color;
  padding: 4px 12px;
  border-radius: 12px;
  font-weight: 600;
  margin-bottom: 12px;
}

.app-store-image{
  padding: 0 8px;
  width: 155px;
}

@media screen and (max-width: $menuChangedPx) {
  .app-signup-installer {
    .app-signup-installer-mobile-image {
      display: block !important;
      width: 100%;
      backdrop-filter: blur(7px);
      background-color: rgba(63, 62, 62, 0.68);
      img{
        transform: translateX(100%);
        position: unset;
        width: 50%;
      }
    }

    .app-signup-installer-description{
      justify-content: normal;
    }
    .app-signup-installer-body{
      .app-fake-search{
        width: 95%;
      }
    }
    .app-signup-qr-code {
      margin-top: 50px;
      width: 55%;

      .app-signup-qr-code-helper {
        display: block;
        right: -100px;
        top: -14%;

        span {
          top: -28px;
          left: -6px;
          width: 124px;
        }

        .app-signup-qr-code-arrow{
          overflow-x: clip;
        }
        svg {
          transform: rotate(-57deg);
          width: 155px;
          height: 155px;
        }
      }
    }

    .app-signup-installer-body {
      margin: 0 43px;
      padding: unset;
    }

    .app-signup-installer-description-text {
      margin: 40px 43px;
    }
  }
}

@media screen and (max-width: 1700px) {
  .app-signup-installer-mobile-image {
    display: none;
  }
}

@media screen and (max-width: 1400px) {

  .app-signup-installer {
    flex-direction: column-reverse;
    height: unset;

    .app-signup-installer-mobile-image {
      display: none;
    }

    .app-w-50pcnt {
      width: 100%;
    }

    .app-signup-logo {
      margin-top: 15%;
      margin-bottom: 15%;
      width: 50%;
    }

    .app-link-primary-button {
      margin-bottom: 20px;
    }
  }

}
