.app-popup-tooltip {
  min-height: 240px;
  height: auto;

  .app-color-primary-green.app-weight-600 {
    display: none;
  }

  .app-popup-title {
    font-size: 16px;
  }

  .app-popup-body {
    padding-bottom: 10px;
    padding-top: 10px;
    background: white;
    padding-left: 52px;
    padding-right: 52px;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 14px;
  }

  .app-popup-buttons .app-secondary-button {
    width: 135px;
    height: 44px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    bottom: 30px;
  }
}
