@import '../../variables';

.chartsContainer {
  display: flex;
  gap: 30px;
  justify-content: stretch;
  flex-wrap: wrap;

  .accountChargesComponent {
    flex: 58%;
  }

  @media screen and (max-width:450px) {
    display: flex;
    flex-direction: column;
  }
}

.chargesContainer {
  margin-top: 16px;

  &.mediumSize {
    width: 195px;
    height: 195px;

    .chargesAmount {
      font: normal normal 600 46px/65px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
    }
  }

  &.smallerSize {
    width: 155px;
    height: 155px;

    .chargesAmount {
      font: normal normal 600 26px/47px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
    }
  }

  &.greenBorder {
    border: 4px $primary-green-color solid;
  }

  &.blueBorder {
    border: 4px $secondary-blue-color solid;
  }

  &.orangeBorder {
    border: 4px $secondary-orange-color solid;
  }

  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .chargesText {
    font: normal normal normal 12px/13px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color2;
    text-transform: uppercase;
  }
}

.costsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin: auto;
    display: block;
  }

  .costsTextAmount {
    font-size: 46px;
    font-weight: 600;
    color: $primary-black-color;
    margin: auto;
    width: min-content;
    padding-top: 16px;

    .costsTextFooter {
      color: black;
    }
  }

  .costsText {
    font: normal normal 600 12px/16px 'myriad-pro';
    letter-spacing: 0;
    color: black;
    text-transform: uppercase;
    text-align: center;
  }
}

.chargesGrowth {
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    width: 12px;
    height: 14px;
  }

  span {
    font: normal normal 600 12px/16px 'myriad-pro';
    text-transform: uppercase;
  }
}

.activityPaper {
  min-height: 250px;
  margin-top: 48px;
  padding-bottom: 32px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .activitySelect {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  :global {
    .app-select .app-select-items {
      top: 26px;
      left: -10px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      .app-select-item {
        font: normal normal 600 15px/21px 'myriad-pro';

        &[aria-selected]:not([aria-selected="true"]):hover {
          color: $primary-black-color;
        }

        &[aria-selected="true"] {
          color: $secondary-blue-color;
          background: white;
          box-shadow: none;
        }
      }
    }

    .app-activity-bar-chart {
      width: 100%;
      flex-grow: 1;
    }
  }

  @media screen and (max-width:450px) {
    margin-top: 30px;
  }
}

.outerContainer {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 42px;

  @media screen and (max-width: 1024px) {
    height: fit-content;
  }
}

.invoiceContainer {
  min-height: 350px;
  padding-left: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .invoiceHeader {
    border-bottom: 4px $primary-green-color solid;
    display: flex;
    margin-bottom: 30px;

    div {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      background: $primary-green-color;
      color: white;
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
      padding: 12px 14px 10px 14px;
    }
  }

  .invoiceTableHeader,
  .invoice {
    display: grid;
    grid-template-columns: repeat(5, 70px) 20px;

    div {
      padding: 0 8px 0 0;
    }
  }

  .invoicesList {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0;
    min-height: 450px;

    :global(.app-table) .invoiceTableHeader .typeColumn,
    .typeColumn {
      display: none;
    }

    .invoiceTableHeader,
    .invoice {
      grid-template-columns: repeat(4, 1fr) 20px;
    }

    .invoicesList .invoice {
      padding: 15px 10px;
    }

    :global(.app-table) .invoiceTableHeader {
      padding: 0 10px;
    }
  }
}

.layoutContainer {
  display: flex;
  height: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
  }

  .layoutContainerItem {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    @media screen and (max-width: 1024px) {
      width: 100%;

      ::v-deep .app-table {
        height: 550px;
      }
    }
  }
}

.paginationContainer {
  display: flex;
  justify-content: center;
}

.billDateFooter {
  margin-top: 42px;
  text-align: center;

  .billDateFooterText {
    text-align: center;
    display: inline-block;
    font: normal normal 600 16px/22px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;

    &.billDateFooterTextGreen {
      color: $primary-green-color;
    }
  }
}

.vatRegisterCostsHighlight {
  color: $primary-green-color;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
}
