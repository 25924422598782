@import '../../../variables';

.app-signup-variant-item-tooltip-trigger {
  display: inline-block;
  position: relative;
  transform: translate(3px, -6px);
  width: 100%;
  white-space: unset;

  img {
    z-index: -1;
    cursor: pointer;
  }

  .app-signup-variant-item-tooltip {
    position: absolute;
    top: 32px;
    left: -38px;
    padding: 26px 14px;
    border: 1px solid $primary-green-color;
    border-radius: 12px;
    box-shadow: 0 2px 6px #00000029;
    background: white;
    max-width: 373px;
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    box-sizing: border-box;

    @media only screen and (min-width: 1423px) {
      width: 300px;
    }

    .app-signup-variant-item-tooltip-arrow {
      position: absolute;
      fill: white;
      width: 20px;
      height: 20px;
      top: -16px;
      left: 32px;
    }

    .app-signup-variant-item-tooltip-description {
      font-size: 16px;
      font-weight: normal;
      color: $primary-black-color2;
    }

    .app-signup-variant-item-tooltip-title {
      font-weight: 600;
      font-size: 20px;
      color: $primary-green-color;
    }
  }
}
