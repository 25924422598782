@import "../../../variables";

.termsAndConditionsWizard {
  &:global(.app-popup-content) {
    box-sizing: border-box;
    gap: 0;
    grid-gap: 0;
    height: 95%;
    width: 95%;
    max-width: 1227px;
    max-height: 700px;
  }

  :global(.app-popup-header) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 46px;

    :global(.app-popup-title) {
      text-transform: uppercase;
      color: $primary-green-color;
      text-align: left;
      font-size: 20px;

      @media screen and (max-width: $menuChangedPx) {
        font-size: 16px;
      }
    }

    :global(.app-popup-subtitle) {
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 8px;
      font-size: 21px;

      @media screen and (max-width: $menuChangedPx) {
        font-size: 16px;
      }
    }

    :global(.app-popup-line) {
      display: none;
    }
  }

  :global(.app-popup-buttons){
    @media screen and (max-width: $popupButtonsChanged) {
      padding-top: 58px;
      button{
        min-width: 25% !important;
      }
    }
  }

  .appPopupBody {
    overflow-y: auto;
    padding-right: 4px;
  }

  .mainContainer {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .pagination {
    display: flex;
    overflow: visible;
    align-self: center;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: $popupButtonsChanged) {
      transform: translateY(-58px);
    }

    .bullet {
      border: 2.2px solid $primary-green-color;
      border-radius: 18px;
      height: 18px;
      width: 18px;
      background: $primary-white-color;
      transition: all 0.3s;
      margin-left: 12.5px;
      margin-right: 12.5px;

      &.selected {
        width: 34px;
        background: $primary-green-color;
        margin-left: 8.5px;
        margin-right: 8.5px;
      }
    }
  }

  .paymentType {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    margin: auto;
    height: fit-content;
    padding-bottom: 40px;
    box-sizing: border-box;
    
    &.taxCertificateRequired {
      margin-top: 0;
      padding-bottom: 0;
    }

    .radioText {
      flex-grow: 1;
    }

    label {
      display: flex;
      align-items: center;
      border: 2px solid $primary-green-color;
      border-radius: 15px;
      padding: 20px 42px 20px 16px;
      background-color: $primary-white-color;

      svg {
        flex-shrink: 0;
        height: 62px;
        width: 62px;
        margin-right: 20px;

        @media screen and (max-width: $menuChangedPx) {
          height: 45px;
          width: 45px;
          margin-right: 14px;
        }
      }

      .labelHeader {
        color: $primary-green-color;
        font-weight: 600;
        font-size: 20px;

        @media screen and (max-width: $menuChangedPx) {
          font-size: 16px;
        }
      }

      .labelBody {
        font-size: 16px;

        @media screen and (max-width: $menuChangedPx) {
          font-size: 13px;
        }
      }

      input[type="radio"] {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        appearance: none;
        -webkit-appearance: none;
        display: flex;
        min-width: 22px;
        min-height: 22px;
        margin-left: 10px;

        &::after {
          content: "";
          display: block;
          box-sizing: border-box;
          height: 22px;
          width: 22px;
          border: 3px solid $primary-green-color;
          border-radius: 50%;
        }

        &:checked {
          &:after {
            margin-top: -22px;
          }

          &::before {
            content: "";
            box-sizing: border-box;
            border: 5px solid $primary-white-color;
            display: block;
            height: 22px;
            width: 22px;
            background-color: $primary-green-color;
            border-radius: 50%;
          }
        }
      }

      @media screen and (max-width: $menuChangedPx) {
        padding: 20px 17px 20px 11px;
      }
    }
  }

  .tosContainer {
    height: calc(100% - 50px);
    box-sizing: border-box;
    background-color: $lighter-gray-color;
    padding: 10px 4px 10px 14px;
    box-shadow: inset 0 1.5px 1.5px #00000029;
    margin-bottom: 8px;
    white-space: pre-line;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .agreeToTosCheckbox {
    align-self: flex-start;
    display: flex;
    align-items: center;
    margin-left: 26px;
    transition: opacity 0.5s;
    cursor: pointer;

    label {
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
    }

    &.disabled {
      opacity: 50%;
      cursor: default;

      label {
        cursor: default;
      }

      input {
        cursor: default;
      }
    }

    input[type="checkbox"] {
      appearance: none;
      outline: 1px $primary-black-color solid;
      margin-right: 15px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        height: 28px;
        width: 28px;
        border-radius: 2.5px;
        box-shadow: 0 1.33px 1.33px #00000029;
        background-color: $primary-white-color;
        cursor: pointer;
      }

      &:checked {
        &::after {
          content: "";
          display: block;
          height: 22px;
          width: 22px;
          margin-left: -25px;
          background-image: url("../../../assets/icons/Tick\ Valid\ icon.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &.pricingPage {
    :global(.app-popup-subtitle) {
      font-size: 33px;
      margin-top: 18px;

      @media screen and (max-width: $menuChangedPx) {
        font-size: 22px;
      }
    }

    .pricingHeading {
      color: $primary-green-color;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 7px;
    }

    .pricingSubheading {
      font-weight: bold;
      font-size: 21px;
      margin-bottom: 14.5px;
    }

    .pricingText {
      margin-bottom: 48px;
    }
  }

  .cardDetailsPage {
    flex-grow: 9999;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    iframe {
      height: 100%;
      width: 100%;
      background-color: $primary-white-color;
    }
  }

  .desktopPaymentPage {
    display: flex;
    height: 100%;
    padding-bottom: 40px;
    padding-top: 5px;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 9999;

    > * {
      flex-basis: 50%;
    }

    .paymentType {
      margin-left: 54px;
    }

    .cardDetailsPage {
      margin-left: 53px;
      margin-right: 10px;
    }
  }


  .appInvoicePdf{
    height: 100%;
    > svg {
      height: 100%;
      width: auto;
      display: block;
      margin: 0 auto;
      box-shadow: 0 3px 6px $border-color;
    }
  }
  
}

