@import '../../variables';

.app-signup-input-container {
  position: relative;
  max-width: 411px;
  width: 411px;
  width: 100%;
  display: flex;

  .app-signup-input-prefix {
    position: absolute;
    width: 35px;
    left: 16px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  .app-signup-input-required {
    position: absolute;
    right: -16px;
    font-size: 125%;
    color: $required-mark-color;
  }

  .app-signup-form-select {
    .app-signup-input {
      text-indent: 0;
    }

    .app-form-input-prefix img {
      width: 2em !important;
      height: 2em !important;
    }

    .app-signup-select-input-prefix {
      position: absolute;
      top: 4px;
      left: 4px;
    }

    .app-select-items {
      top: 52px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 97%;
      left: 1.5%;

      div {
        border-radius: 0;
      }
    }

    &.app-select-not-in-view {
      .app-select-items {
        top: -203px;
        border-radius: 12px 12px 0 0;
      }
    }

    &.app-signup-select-dialling-code {
      max-width: 200px; // width could reduced now, but there are some big mobile codes, so space reserved for future, e.g. if add new country in countries.ts, the UI will not be broken
      .app-signup-input {
        text-indent: 28px;
      }

      .app-form-input-prefix {
        left: 54px;
      }
    }
  }

  .app-signup-input {
    box-shadow: inset 0px -1px 2px #00000029;
    border: 1px solid #6C6C6B;
    border-radius: 12px;
    width: 100%;
    padding: 13px 20px 13px 65px;
    font-size: 20px;
    outline: none;
    box-sizing: border-box;

    &.app-signup-input-mobile {
      padding-left: 20px;
    }

    &[data-invalid="true"] {
      border: 1px solid $secondary-red-color;
    }

    &:disabled {
      background: hexToRGBA(#B1B1B1, 0.11);
      border: 1px solid hexToRGBA(#B1B1B1, 0.11);
      color: hexToRGBA($primary-black-color, 0.4);
    }

    &::placeholder {
      color: #B1B1B1;
    }

    &:focus {
      border: 1px solid $primary-green-color;
    }
  }
}

.app-signup-input-error {
  color: $secondary-red-color;
  font: normal normal normal 16px/16px 'myriad-pro';
  display: inline-block;
  padding-left: 6px;
  padding-top: 6px;

  &.app-signup-input-error-padding-select {
    padding-left: 15px;
  }

  &.app-pl-50pcnt{
    padding-left: 50%;
  }
}

.app-signup-button-paddings {
  padding: 15px 12px;
}

@media screen and (max-width: 1002px) {
  .app-signup-layout-base {
    .app-signup-logo + div {
      max-width: 411px;
    }
  }
}
