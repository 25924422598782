@import '../../variables';

.app-signup-success {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 22px;
    color: $primary-black-color;
    display: inline-block;
    max-width: 658px;
    text-align: center;
    padding-top: 97px;
    padding-bottom: 76px;
  }
}

@media only screen and (max-width: $signUpSharedBreakPoint) {
  .app-signup-success {
    height: unset;

    svg {
      margin-top: 60px;
    }
  }
}
