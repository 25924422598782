@import '../../../variables';

.app-signup-vat-installer-mobile-steps-container {
  display: none;
}

.app-signup-vat-installer-steps-container {
  background-color: hexToRGBA(#3F3E3E, 0.68);
  position: absolute;
  height: 100vh;
  color: white;
  width: 100%;
}

.app-signup-vat-installer-steps {
  font-size: 23px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 45px;
  width: fit-content;
}

.app-signup-number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;

  &.app-signup-number-container-current {
    font-weight: bold;
  }

  .app-signup-step-number {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    box-shadow: 0 1px 1px #00000029;
    border: 2px solid #F5F5F5;
    font-weight: normal;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.app-signup-step-number-current {
      background: #F5F5F5;
      color: #474442;
    }
  }
}

.app-signup-personal-info-logo {
  position: absolute;
  top: 44px;
  right: 75px;
  width: 216px;
  height: 62px;
}

.app-signup-personal-layout-start {
  margin-top: 180px;
  width: 411px;
}

.app-signup-overflow-side {
  overflow-y: auto;
  max-height: 100vh;
}

@media screen and (max-width: $signUpSharedBreakPoint) {
  .app-signup-personal-layout-base {
    justify-content: center;
    height: 100vh;
    align-items: center;

    .app-signup-vat-installer-mobile-steps-container {
      display: flex;
      margin-bottom: 30px;
      gap: 8px;

      .app-signup-step-title {
        display: none;
      }

      .app-signup-step-number {
        color: $primary-green-color;
        border: 2px $primary-green-color solid;

        &.app-signup-step-number-current {
          background: $primary-green-color;
          color: $primary-white-color;
          border: none;
          line-height: 31px;

          & + .app-signup-step-title {
            display: block;
            font-weight: 600;
            color: $primary-black-color;
            font-size: 20px;
            margin-right: 4px;
          }
        }
      }
    }

    .app-signup-personal-layout-start {
      margin-top: 15%;
      margin-bottom: 30px;
    }

    .app-w-50pcnt {
      width: 100%;

      & > img {
        position: unset;
      }
    }

    & > div:first-child {
      display: none;
    }

    & > div {
      padding: 10px;
      box-sizing: border-box;
    }

    & > div, & > div > div {
      width: 100%;
      max-width: 411px;
    }

    .app-primary-button, .app-secondary-button {
      width: 50%;
    }
  }
}
