@import '../../variables';

$tooltipIsTooBigPoint: 550px;

.app-signup-layout {
  .app-region-label {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .app-signup-region-tooltip {
    position: absolute;
    right: -28px;
    top: 24px;

    .app-signup-variant-item-tooltip-trigger {
      img {
        width: 19px;

        @media screen and (max-width: $tooltipIsTooBigPoint) {
          transform: translateX(-7px);
        }
      }

      .app-signup-variant-item-tooltip {
        width: 378px;
        max-width: none;
        left: -302px;
        top: 35px;

        @media screen and (max-width: $tooltipIsTooBigPoint) {
          width: 322px;
          max-width: none;
          left: -304px;
          top: 35px;
        }

        .app-signup-variant-item-tooltip-arrow {
          left: 300px;

          @media screen and (max-width: $tooltipIsTooBigPoint) {
            left: 295px;
          }
        }
      }
    }
  }

  .app-select {
    width: 100%;
    max-width: 373px;

    &.app-popup-menu-shown {
      .app-signup-region-container {
        border: 1px solid $primary-green-color;
      }
    }

    .app-select-trigger-container {
      width: 100%;
      max-width: 373px;
      & > div {
        width: 100%;
        max-width: 373px;
      }
    }

    .app-signup-region-container {
      border: 1px solid #6C6C6B;
      border-radius: 12px;
      width: 100%;
      max-width: 373px;
      padding: 12px 15px;
      font-size: 20px;
      box-sizing: border-box;
    }

    .app-select-items[aria-hidden="false"] {
      width: 95%;
      top: 52px;
      left: 2.5%;
      height: 207px;
      max-height: unset;

      .app-select-item {
        font-size: 15px;
        padding-top: 9.5px;
        padding-bottom: 9.5px;
        box-sizing: border-box;
      }
    }
  }

  .app-signup-logo + div {
    width: 100%;
    max-width: 373px;
  }

  .app-primary-button {
    width: 100%;
    max-width: 373px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: none;
  }
}

@media only screen and (max-width: 1002px) {
  .app-signup-layout-base {
    align-items: center;
    justify-content: center;

    .app-w-50pcnt {
      width: 100%;
      height: 100vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & > img {
      display: none;
    }

    .app-signup-logo {
      margin-top: 0;
      margin-bottom: 15%;
      width: 70%;

      & + div {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
}
