@import '../../variables';

@media (max-width: 1423px) {
  .app-home-top-charts {
    grid-template-columns: repeat(1, 1fr);

    .app-home-paper-next-bill {
      grid-column: unset;
    }
  }

  .app-grid-2-cols-gap-30 {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .app-home-paper-account-activity {
    height: 380px;
  }
}

@media (max-width: $homePagePoint) {
  .app-home-top-charts {
    grid-template-columns: repeat(1, 1fr);

    .app-home-paper-next-bill {
      grid-column: unset;
    }
  }
}
