@import '../../variables';

.app-system-details-papers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 40px;
  font-family: 'myriad-pro', serif;

  @media screen and (max-width: 1528px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.app-system-details-paper {
  background: white;
  padding: 17px 23px;
  border-radius: 12px;

  .app-system-details-paper-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .app-system-details-paper-content {
    .app-system-details-paper-content-item {
      display: flex;
      align-items: normal;
      margin-bottom: 30px;

      .app-system-details-paper-content-item-icon {
        margin-right: 16px;
        width: 30px;
        height: 30px;
      }

      .app-system-details-paper-content-item-content {
        font-size: 20px;
        color: $primary-black-color;
      }
    }
  }
}
