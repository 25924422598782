@import '../../variables';

$pdfTableGreen: hexToRGBA(#56AA46, 0.8);
$pdfTableXPadding: 29px;

.app-invoice-pdf {
  .app-invoice-pdf-header {
    padding: 26px 30px 16px;
  }

  .app-invoice-pdf-currency {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 31px;

    span {
      font-size: 17px;
      color: $primary-black-color;
      font-weight: normal;
    }
  }

  .app-invoice-pdf-actions {
    display: flex;
    gap: 15px;

    span {
      font-size: 20px;
    }
  }

  .app-invoice-background-image{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;

    svg{
      scale: 0.75;
      transform: translateX(-410px) translateY(105px);
      height: 100%;
      z-index: 0;
    }
  }

  .app-invoice-pdf-html-preview {
    background: $primary-white-color;
    background-image: url('../../assets/icons/Modal icons/modal-background.svg');
    background-repeat: no-repeat;
    background-size: 150%;
    background-position-y: 100%;
    background-position-x: 23%;
    margin: 20px auto;
    width: calc(100% - 170px);
    max-width: 1344px;
    border-top: 19px solid $primary-green-color;
    border-bottom: 19px solid $primary-green-color;
    padding: 90px 60px 0;

    .app-invoice-pdf-html-preview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;

      div {
        display: flex;
        flex-direction: column;

        span {
          font-size: 51px;
          line-height: 40px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    .app-invoice-pdf-html-preview-details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;

      .app-invoice-pdf-html-to {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 600;
        color: $primary-green-color;
        margin-bottom: 11px;
      }

      .app-invoice-pdf-html-preview-details-left {
        display: flex;
        flex-direction: column;
        gap: 13px;

        .app-invoice-pdf-html-preview-details-left-row {
          font-size: 20px;
          color: $primary-black-color;
        }
      }

      .app-invoice-pdf-html-preview-details-right {
        display: flex;
        flex-direction: column;
        gap: 13px;

        .app-invoice-pdf-html-preview-row {
          font-size: 20px;
          color: $primary-black-color;
          display: flex;
          justify-content: space-between;
          width: 531px;
        }
      }
    }

    .app-invoice-pdf-html-preview-table {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      background: $primary-white-color;
      position: relative;
      z-index: 2;

      .app-invoice-pdf-html-preview-table-header {
        .app-invoice-pdf-html-preview-table-header-row {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          background-color: $pdfTableGreen;
          display: grid;
          grid-template-columns: 5fr 1fr 1fr 1fr;
          font-size: 20px;
          font-weight: 600;
          text-transform: uppercase;
          padding: 24px $pdfTableXPadding;
          align-items: center;

          .app-invoice-pdf-html-preview-table-header-row-cell:last-child {
            text-align: right;
          }
        }
      }

      .app-invoice-pdf-html-preview-table-body {
        .app-invoice-pdf-html-preview-table-body-row {
          display: grid;
          grid-template-columns: 5fr 1fr 1fr 1fr;
          align-items: flex-end;
          font-size: 20px;
          padding: 20px $pdfTableXPadding;

          &:nth-child(odd) {
            background-color: hexToRGBA(#F1F1F2, 0.28);
          }

          .app-invoice-pdf-html-preview-table-unit-item {
            text-indent: 30px;
          }

          .app-invoice-pdf-html-preview-table-body-row-cell:last-child {
            text-align: right;
          }

          .app-invoice-pdf-html-preview-table-body-row-cell:first-child {
            width: 50%;
          }
        }
      }

      .app-invoice-pdf-html-preview-table-footer {
        margin-top: 61px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .app-invoice-pdf-html-preview-table-footer-row {
          display: flex;
          justify-content: space-between;
          width: 336px;
          font-size: 20px;
          line-height: 28px;
          padding-right: 27px;
          text-indent: 29px;

          &.app-invoice-pdf-html-preview-table-footer-row-final {
            background: $pdfTableGreen;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding: 17px 27px;
            margin-top: 17px;
            width: 305px;
            text-indent: 0;
            font-weight: 600;
          }

          .app-invoice-pdf-html-preview-table-footer-row-label{
            word-wrap: break-word;
          }
        }
      }
    }

    .app-invoice-pdf-html-preview-terms {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 248px;
      margin-top: 168px;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .app-invoice-pdf-html-preview-footer {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 44px;
    }

    .app-invoice-pdf-html-preview-paging {
      color: $primary-green-color;
      font-size: 17px;
      text-align: right;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 1088px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview {
    zoom: 0.6;
  }
}

@media screen and (max-width: 800px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview {
    width: calc(100% - 135px);
  }
}

@media screen and (max-width: 540px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview {
    zoom: 0.4;
  }
}

@media screen and (max-width: 376px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview {
    zoom: 0.3;
  }
}

@media screen and (max-width: 240px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview {
    zoom: 0.2;
  }
}

@media screen and (max-width: 467px) {
  .app-invoice-pdf {
    .app-invoice-pdf-header {
      padding: 10px;
      .app-invoice-pdf-currency {
        font-size: 20px;
        line-height: 21px;

        span {
          font-size: 14px;
        }
      }

      .app-invoice-pdf-actions {
        span {
          font-size: 12px;
        }

        button {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1319px) {
  .app-invoice-pdf .app-invoice-pdf-html-preview .app-invoice-pdf-html-preview-table .app-invoice-pdf-html-preview-table-header .app-invoice-pdf-html-preview-table-header-row {
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }

  .app-invoice-pdf .app-invoice-pdf-html-preview .app-invoice-pdf-html-preview-table .app-invoice-pdf-html-preview-table-body .app-invoice-pdf-html-preview-table-body-row {
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }
}
