@import './variables';

.app-mt--20 {
  margin-top: -20px;
}

.app-d-flex {
  display: flex;
}

.app-d-table {
  display: table;
}

.app-flex-1 {
  flex: 1;
}

.app-pt-62 {
  padding-top: 62px;
}

.app-pt-46 {
  padding-top: 46px;
}

.app-mh-calc-150 {
  height: calc(100% - 100px);
  max-height: calc(100% - 100px);
}

.app-mb-50 {
  margin-bottom: 50px;
}

.app-pb-33 {
  padding-bottom: 33px;
}

.app-mb-40 {
  margin-bottom: 40px;
}

.app-pt-20 {
  padding-top: 20px;
}

.app-gap-20 {
  gap: 20px;
}

.app-pt-30 {
  padding-top: 30px;
}

.app-mr-20 {
  margin-right: 20px;
}

.app-mr-30 {
  margin-right: 30px;
}

.app-text-align-right {
  text-align: right;
}

.app-text-align-center {
  text-align: center;
}

.app-id-text{
  font: normal normal bold 107px/149px 'myriad-pro';
}

.app-mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.app-my-auto{
 margin-top: auto;
 margin-bottom: auto;
}

.app-m-auto{
  margin: auto;
}

.app-pointer {
  cursor: pointer;
}

.app-mw-527 {
  max-width: 527px;
}

.app-mw-411 {
  max-width: 411px;
}

.app-w-411 {
  width: 411px;
}

.app-flex-column {
  flex-direction: column;
}

.app-grow-1 {
  flex-grow: 1;
}

.app-justify-content-between {
  justify-content: space-between;
}

.app-justify-content-around {
  justify-content: space-around;
}

.app-justify-content-center {
  justify-content: center;
}

.app-place-center {
  place-content: center;
}

.app-justify-content-end {
  justify-content: flex-end;
}

.app-align-items-center {
  align-items: center;
}

.app-h-80 {
  height: 80px;
}

.app-h-66 {
  height: 66px;
}

.app-h-26 {
  height: 26px;
}

.app-py-36 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.app-pb-10 {
  padding-bottom: 10px;
}

.app-px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.app-py-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.app-pl-50pcnt{
  padding-left: 50%;
}

.app-px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.app-py-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}

.app-py-62 {
  padding-top: 62px;
  padding-bottom: 62px;
}

.app-mr-9 {
  margin-right: 9px;
}

.app-px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.app-pr-169 {
  padding-right: 169px;
}

.app-pl-60 {
  padding-left: 60px;
}

.app-pr-60 {
  padding-right: 60px;
}

.app-pr-50 {
  padding-right: 50px;
}

.app-py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.app-mr-20 {
  margin-right: 12px;
}

.app-mb-30 {
  margin-bottom: 30px;
}

.app-mb-46 {
  margin-bottom: 46px;
}

.app-whitespace-nowrap {
  white-space: nowrap;
}

.app-w-521 {
  width: 521px;
}

.app-mr-200 {
  margin-right: 200px;
}

.app-mr-auto {
  margin-right: auto;
}

.app-w-100pcnt {
  width: 100%;
}

.app-w-50pcnt {
  width: 50%;
}

.app-mr-29 {
  margin-right: 29px;
}

.app-mb-90 {
  margin-bottom: 90px;
}

.app-d-inline-block {
  display: inline-block;
}

.app-d-block {
  display: block;
}

.app-mw-500 {
  max-width: 500px;
}

.app-mb-65 {
  margin-bottom: 65px;
}

.app-mb-44 {
  margin-bottom: 44px;
}

.app-w-133 {
  width: 133px;
}

.app-min-w-200 {
  min-width: 200px;
}

.app-h-118 {
  height: 118px;
}

.app-w-86 {
  width: 86px;
}

.app-h-163 {
  height: 163px;
}

.app-mb-57 {
  margin-bottom: 57px;
}

.app-mb-60 {
  margin-bottom: 60px;
}

.app-pl-80 {
  padding-left: 80px;
}

.app-pl-10 {
  padding-left: 10px;
}

.app-pl-18 {
  padding-left: 18px;
}

.app-pr-31 {
  padding-right: 31px;
}

.app-mt-52 {
  margin-top: 52px;
}

.app-mt-5 {
  margin-top: 5px;
}
.app-mt-10 {
  margin-top: 10px;
}

.app-mb-37 {
  margin-bottom: 37px;
}

.app-mb-75 {
  margin-bottom: 75px;
}

.app-w-160 {
  width: 160px;
}

.app-h-128 {
  height: 128px;
}

.app-mb-68 {
  margin-bottom: 68px;
}

.app-w-50pcnt {
  width: 50%;
}

.app-w-95pcnt{
  width: 95%;
}

.app-h-100vh {
  height: 100vh;
}

.app-h-100pcnt {
  height: 100%;
}

.app-mb-86 {
  margin-bottom: 86px;
}

.app-mb-100 {
  margin-bottom: 100px;
}

.app-mt-36 {
  margin-top: 36px;
}

.app-cursor-pointer {
  cursor: pointer;
}

.app-cursor-arrow {
  cursor: default;
}

.app-mt-90 {
  margin-top: 90px;
}
.app-mt-150 {
  margin-top: 150px;
}

.app-mt-52 {
  margin-top: 52px;
}

.app-mt-35 {
  margin-top: 35px;
}

.app-font-20 {
  font: normal normal normal 20px/20px 'myriad-pro';
}

.app-font-21-25 {
  font: normal normal normal 21px/25px 'myriad-pro';
}

.app-font-21-35 {
  font: normal normal normal 21px/35px 'myriad-pro';
}

.app-font-20-35 {
  font: normal normal normal 20px/35px 'myriad-pro';
}

.app-pl-20 {
  padding-left: 20px;
}

.app-font-28 {
  font-size: 28px;
}

.app-mb-107 {
  margin-bottom: 107px;
}

.app-uppercase {
  text-transform: uppercase;
}

.app-weight-600 {
  font-weight: 600;
}

.app-w-30 {
  width: 30px;
}

.app-h-30 {
  height: 30px;
}

.app-w-33 {
  width: 33px;
}

.app-position-relative {
  position: relative;
}

.app-h-508 {
  height: 508px;
}

.app-mt-64 {
  margin-top: 64px;
}

.app-flex-wrap {
  flex-wrap: wrap;
}

.app-w-1227 {
  width: 1227px;
}

.app-mw-none {
  max-width: none;
}

.app-h-746 {
  height: 746px;
}

.app-text-align-right {
  text-align: right;
}

.app-mt-33 {
  margin-top: 33px;
}

.app-mr-38 {
  margin-right: 38px;
}

.app-ml-99 {
  margin-left: 99px;
}

.app-ml-55 {
  margin-left: 55px;
}

.app-mb-26 {
  margin-bottom: 26px;
}

.app-mw-101 {
  min-width: 101px;
}

.app-weight-normal {
  font-weight: normal;
}

.app-max-w-716 {
  max-width: 716px;
}

.app-ml-28 {
  margin-left: 28px;
}

.app-w-975 {
  width: 975px;
}

.app-mt-26 {
  margin-top: 26px;
}

.app-mt-22 {
  margin-top: 22px;
}

.app-mb-7 {
  margin-bottom: 7px;
}

.app-mb-32 {
  margin-bottom: 32px;
}

.app-mb-36 {
  margin-bottom: 36px;
}

.app-w-130 {
  width: 130px;
}

.app-h-31 {
  height: 31px;
}

.app-color-primary-green {
  color: $primary-green-color;
}

.app-color-secondary-red {
  color: $secondary-red-color;
}

.app-color-secondary-yellow {
  color: $secondary-yellow-color
}

.app-color-secondary-blue {
  color: $secondary-blue-color
}

.app-mt-43 {
  margin-top: 43px;
}


.app-color-primary-black {
  color: $primary-black-color;
}

.app-color-primary-black2 {
  color: $primary-black-color2;
}

.app-font-16-22 {
  font: normal normal normal 16px/22px 'myriad-pro';
}

.app-font-11-15 {
  font: normal normal normal 11px/15px 'myriad-pro';
}

.app-w-170 {
  width: 170px;
}

.app-ml-18 {
  margin-left: 18px;
}


.app-ml-8 {
  margin-left: 8px;
}

.app-h-262 {
  height: 262px;
}

.app-w-20 {
  width: 20px;
}

.app-mt-28 {
  margin-top: 28px;
}

.app-mb-17 {
  margin-bottom: 17px;
}

.app-min-w-1 {
  min-width: 1px;
}

.app-flex-basis-90 {
  flex-basis: 90%;
}

.app-flex-grow-1 {
  flex-grow: 1;
}

.app-ellipsis {
  flex-basis: 90%;
  min-width: 1;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.app-line-clamp-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.app-pr-12 {
  padding-right: 12px;
}

.app-pr-10 {
  padding-right: 10px;
}

.app-mt-57 {
  margin-top: 57px;
}

.app-mb-141 {
  margin-bottom: 141px;
}

.app-mw-373 {
  max-width: 373px;
}

.app-pl-51 {
  padding-left: 51px;
}

.app-font-24 {
  font-size: 24px;
}

.app-pt-5 {
  padding-top: 5px;
}

.app-pt-10 {
  padding-top: 10px;
}

.app-pt-16 {
  padding-top: 16px;
}

.app-pt-20 {
  padding-top: 20px;
}

.app-mb-20 {
  margin-bottom: 20px;
}

.app-flex-direction-column {
  flex-direction: column;
}

.app-mt-231 {
  margin-top: 231px;
}

.app-mb-137 {
  margin-bottom: 137px;
}

.app-gap-23 {
  gap: 23px;
}
.app-gap-50 {
  gap: 50px;
}

.app-gap-8 {
  gap: 8px;
}

.app-gap-4 {
  gap: 4px;
}

.app-text-transform-none {
  text-transform: none;
}

.app-text-transform-capitalize {
  text-transform: capitalize;
}

.app-invisible {
  visibility: hidden;
}

.app-text-bold {
  font: bold 16px/21px "myriad-Pro";
}

.app-fit-cover {
  object-fit: cover;
}

.app-font-9 {
  font-size: 9px;
}

.app-fit-content {
  width: fit-content;
}

.app-hidden {
  visibility: hidden;
}

.app-gap-30 {
  gap: 30px;
}

.app-mb-12 {
  margin-bottom: 12px;
}

.app-mb-24 {
  margin-bottom: 24px;
}

.app-mb-28 {
  margin-bottom: 28px;
}

.app-lh-22 {
  line-height: 22px;
}

.app-lh-28 {
  line-height: 28px;
}

.app-font-40 {
  font-size: 40px;
}

.app-gap-50 {
  gap: 50px;
}

.app-gap-48 {
  gap: 48px;
}

.app-container-inline-size {
  container-type: inline-size;
}

@container (max-width: 500px) {
  .app-container-hidden-500 {
    display: none;
  }
}

.app-container-visible-500-flex {
  display: none;
}

@container (max-width: 500px) {
  .app-container-visible-500-flex {
    display: flex;
  }
}

.app-w-fit-content {
  width: fit-content;
}

.app-mr-98 {
  margin-right: 98px;
}

.app-w-fit-content {
  width: fit-content;
}

.app-gap-15 {
  gap: 15px;
}

.app-border-green {
  border: 1px solid $primary-green-color;
}

.app-h-25 {
  height: 25px;
}

.app-h-100 {
  height: 100px;
}

.app-justify-content-space-evenly {
  justify-content: space-evenly;
}

.app-grid-2-cols-gap-30 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
}

.app-gap-27 {
  gap: 27px;
}

.app-font-19 {
  font-size: 19px;
}
