@import '../../../../variables';

.filesDropZoneWrapper {
  position: relative;
  margin: auto;
  height: 160px;
  border: 1px dashed $primary-green-color;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  &.isDragging {
    background: hexToRGBA($primary-green-color, 0.5);
  }
}

.uploadedFile {
  padding: 18px 31px 14px;
  border-top: 1px $primary-green-color solid;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .uploadedFileName {
    font: normal normal normal 11px/15px 'myriad-pro'
  }

  .deleteButton {
    border: 0;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.filesDropZone {
  height: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
