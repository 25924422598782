@import '../../../../variables';

.appTablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;

    &.appTablePaginationDisabled {
      cursor: default;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;

    &:last-child {
      margin-left: 0;
    }
  }

  .appTablePaginationPage {
    font: normal normal 600 18px/18px 'myriad-pro';
    color: $primary-black-color;
    margin-right: 7px;
    cursor: pointer;

    &.appTablePaginationPageSingle {
      cursor: default;
    }

    &.appTablePaginationPageActive {
      color: $primary-white-color;
      background-color: $primary-green-color;
      width: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
    }
  }

}
