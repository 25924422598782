@import '../../variables';

.app-home-top-charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 40px;
}

@media (max-width: $homePagePoint) {
  .app-home-top-charts {
    grid-template-columns: repeat(2, 1fr);

    .app-home-paper-next-bill {
      grid-column: 1 / 3;
    }
  }
}

@media (max-width: 520px) {
  .app-home-top-charts {
    display: flex;
    flex-direction: column;
  }

  .app-home-paper-activity-title {
    flex-direction: column;
  }
}

.app-activity-bar-chart {
  width: 100%;
  height: 100%;

  @media (max-width: $homePagePoint) {
    & {
      height: 300px;
    }
  }
}

.app-home-paper {
  padding: 20px 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-white-color;
  border-radius: 12px;

  &.app-h-343 {
    height: 343px;
  }

  &.app-home-paper-no-paddings {
    padding: 0;
  }

  &.app-h-540 {
    height: 540px;
  }

  .app-home-paper-title {
    width: 100%;
    text-align: left;
    margin-bottom: 35px;
    font: normal normal 600 20px/28px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;
  }

  .app-home-estimated-next-bill {
    .app-home-estimated-next-bill-text {
      font: normal normal normal 9px/13px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color2;
      text-transform: uppercase;
    }

    .app-home-estimated-next-bill-value {
      font: normal normal 600 46px/65px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
    }
  }

  .app-home-total-devices {
    display: flex;
    flex-direction: column;
    place-content: center;

    .app-home-total-devices-text {
      font: normal normal 600 40px/56px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
      text-transform: uppercase;
    }
  }

  .app-home-total-systems {
    display: flex;
    flex-direction: column;
    place-content: center;
    border: #2E6D96 8px solid;
    border-radius: 50%;
    width: 180px;
    height: 180px;

    .app-home-total-systems-text {
      font: normal normal 600 40px/56px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .app-home-paper-footer {
    margin-top: 42px;

    .app-home-paper-footer-text {
      text-align: center;
      display: inline-block;
      font: normal normal 600 16px/22px 'myriad-pro';
      letter-spacing: 0;
      color: $primary-black-color;
      text-transform: uppercase;

      &.app-home-paper-footer-text-green {
        color: $primary-green-color;
      }
    }

    .app-home-paper-footer-growth {
      align-items: center;
      display: flex;

      svg {
        transform: translatey(10px);
        margin-right: 9px;
      }

      &.app-home-paper-sites-growth-icon {
        svg {
          transform: translateY(1px);
        }
      }
    }
  }
}

.app-home-paper-activity-title {
  width: 100%;
  text-align: left;
  letter-spacing: 0;
  color: $primary-black-color;
  text-transform: uppercase;
  padding: 20px 29px 0 29px;
  box-sizing: border-box;

  .app-home-paper-activity-title-text {
    font: normal normal 600 20px/28px 'myriad-pro';
  }

  .app-home-paper-activity-bar-select {
    font: normal normal 600 16px/22px 'myriad-pro';
  }

  .app-select .app-select-items {
    top: 26px;
    left: -10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .app-select-item {
      font: normal normal 600 15px/21px 'myriad-pro';

      &[aria-selected]:not([aria-selected="true"]):hover {
        color: $primary-black-color;
      }
      
      &[aria-selected="true"] {
        background: none;
        box-shadow: none;
      }

      &[aria-selected="true"] .app-selected-secondary-blue {
        color: $secondary-blue-color;
      }
      
      &[aria-selected="true"] .app-selected-secondary-orange {
        color: $secondary-orange-color;
      }
    }
  }
}
