@import '../../../variables.scss';

.app-form{
    height: 100%;
    padding: 42px 60px;
    overflow: auto;

    .app-form-bottom-action-bar{
        padding-top: 10px;
    }
    .app-form-container {
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        .app-form-label,
        input {
            font-size: 17px;
        }

        .app-form-column {
            flex-basis: 50%;

            &.app-form-column-left {
                @media screen and (min-width: 1200px) {
                    padding-right: 50px;
                }

                @media screen and (min-width: 1450px) {

                    padding-right: 100px;
                }
            }

            &.app-form-column-right {
                @media screen and (min-width: 1200px) {
                    padding-left: 50px;
                }

                @media screen and (min-width: 1450px) {

                    padding-left: 100px;
                }
            }
        }

        .app-form-separator {
            @media screen and (max-width: 1200px) {
                flex-basis: 0;
            }

            flex-basis: 2px;
            background-image: linear-gradient(white 50%, rgba(255, 255, 255, 0) 0%);
            background-position: right;
            background-size: 30px 30px;
            background-repeat: repeat-y;
            background-position-y: inherit;
        }
    }
}


@media screen and (max-width: 1200px) {
    .form-wide-tooltip{
        flex-direction: column;

        .app-mt-43{
            margin-top: 10px;
        }

        img {
            padding: 10px 0px;
        }
    }
    .app-form-bottom-action-bar{
        padding: 10px 0;
    }
}

@media screen and (max-width: $menuChangedPx) {

    .app-form{
        padding: unset;
    }

    .lkk-switch{
        flex: 1;
    }
    .app-reinstate-button{
        margin-right: 50px;
    }
}
