@import '../../variables';

.notificationContainer {
  margin-bottom: 43px;

  .notificationContainerTitle {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    height: 54px;
    align-items: center;
    padding-left: 28px;
    display: flex;
    background-color: $primary-green-color;
    color: $primary-white-color;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .notificationContainerBody {
    background-color: $primary-white-color;
    font-size: 16px;
    color: $primary-black-color;
    padding: 18px 28px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.formControlShared {
  max-width: 494px;
  width: 100%;

  &.autoPaymentMaxWidth {
    max-width: 716px;
  }

  :global(.app-select-trigger-container) {
    max-width: 494px;
    width: 100%;
  }

  :global(.app-select.app-popup-menu .app-select-items) {
    width: 100%;
    min-width: 320px;

    @media screen and (max-width: 560px) {
      min-width: 200px;
    }

    :global(.app-select-item) {
      width: auto;
      min-width: 100%;
      padding-right: 0;
    }
  }
}

.formattedInvoice {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 50px;
  gap: 10px;
  max-width: calc(100% - 40px);

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr 1fr;

    .hideOnSmall {
      display: none;
    }
  }
}

.payAnInvoice {
  max-width: 768px;
}

.payAnInvoiceInner {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  width: 100%;

  .invoicePopupMenu {
    width: 100%;
    flex: 1 1 180px;
  }

  .invoiceInput {
    width: 100%;
    min-width: 180px;

    .formattedInvoice,
    .invoicePlaceholder {
      position: absolute;
      top: 11px;
      left: 20px;
    }

    .invoicePlaceholder {
      color: $primary-black-color;
      font: normal normal normal 20px/20px 'myriad-pro';
    }

    .invoicePostfix {
      position: absolute;
      top: 11px;
      right: 20px;
    }
  }
}

.tooltip {
  :global(.app-tooltip-body) {
    padding-bottom: 17px !important;
  }
}

.invoiceInput {
  input[type=text] {
    &:disabled {
      background: #B0B0B0 !important;
      opacity: 0.2 !important;
      cursor: default;
    }
  }
}

.invoicePostfix[aria-disabled="true"], .invoicePlaceholder[aria-disabled="true"] {
  cursor: default;
  opacity: 0.2 !important;
}

