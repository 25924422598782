@media (min-width: 919px) {
  .app-sign-up-vat-installer-contact-info-buttons {
    margin-top: 168px;
  }
}

// app-mobile_phone_icon is the id from the svg ..\entry-portal-ui\src\assets\icons\Text field icons\Mobile icon.svg
// app-country-flag-icon is given to the ReactCountryFlag component
#app-mobile_phone_icon,#app-country-flag-icon:hover{
  cursor: pointer;
}
