@import '../../../variables';

.costsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 125px;
  }

  .costsTextAmount {
    font-size: 60px;
    font-weight: 600;
    color: $primary-black-color;
    margin-bottom: 1px;
    text-align: center;
  }

  .costsText {
    font: normal normal 600 13px/19px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;
  }
}