@import "./variables.scss";

.app-signup-top-left-logo {
  position: absolute;
  top: 20px;
  left: 16px;
  z-index: $zindex-dropdown;

  @media screen and (max-width: 1002px) {
    & {
      display: none;
    }
  }
}
