@import '../../variables';

.app-company-details-partner-info-column {
  width: calc(100% - 111px - 48px);
}

.app-system-details-paper-content-item {
  margin-bottom: 30px;
}

.app-system-details-paper-content-item-icon {
  width: 35px;
  text-align: center;
}

.app-admin-company-details-footer-text {
  padding: 31px 0;
  font: normal normal 600 18px/26px "myriad-pro";
  color: #CE171D;
}

.app-contact-check-icon {
  background-color: $primary-black-color;
  border-radius: 50%;
  padding: 4px;
  width: 20px;
  height: 20px;

  path {
    fill: $primary-white-color;
  }
}

.app-company-details-medallion img {
  width: 50px;
  height: 71px;
}
