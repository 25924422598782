.admin-people {
    .admin-people-header {
        @media screen and (max-width:500px) {
            flex-direction: column-reverse;
            a {
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 10px;
            }

            .app-search {
                width: 100%;
                margin-left: 8px;
            }
        }
    }

    .admin-people-table {
        height: 100%;

        .admin-people-table-header {
            display: grid;
            grid-template-columns: repeat(4, 1fr) 27px;
        }

        .admin-people-table-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr) 27px;
        }


        @media screen and (max-width:800px) {
            .admin-people-table-row {
                grid-template-columns: repeat(3, 1fr) 27px;
                padding: 15px 8px;
            }

            .admin-people-email-col {
                display: none;
            }

            .admin-people-table-header {
                grid-template-columns: repeat(3, 1fr) 27px;
                padding: 0 8px;

                .admin-people-email-header {
                    display: none;
                }
            }
        }
    }
}
