@import '../../../variables';

.app-admin-form-site-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-size: 20px;
  color: white;
  background: $secondary-blue-color;
  border-radius: 12px;
  margin-left: 66px;

  svg {
    cursor: pointer;
  }
}

.app-admin-form-site-list {
  max-height: 320px;
  overflow-y: auto;
}