@import 'variables';
@import 'ui-kit';
@import 'utils';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'myriad-pro', serif;
}

.root {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #C3C3C363;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: $primary-black-color;
}


.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  height: 100%;
  transform: translate(0);

  &.app-restricted-height {
    max-height: calc(100vh - 50px);
  }

  .app-navigation-desktop-container {
    display: flex;
    flex-direction: column;
    max-width: 290px;
    min-width: 290px;
    height: 100%;
    box-shadow: 0 3px 6px $border-color;
    z-index: $zindex-fixed;

    .app-navigation-close-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 302px;
      justify-content: end;
      display: none;
      z-index: $zindex-tooltip;
    }

    .app-navigation-overlay {
      display: none;
    }

    .app-navigation-menu-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .app-navigation-links {
      flex-grow: 1;
    }

    .app-navigation-logo {
      padding: 30px 28px;

      img {
        max-height: 54px;
      }
    }
  }

  .app-portal {
    position: relative;
    width: 85%;
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    overflow-x: auto;
  }

  .app-navigation-mobile {
    display: none;
  }

  .app-nowrap {
    white-space: nowrap;
  }

  [data-functionality-limited="true"],
  [data-on-hold="true"] {
    position: relative;

    * {
      filter: inherit;
    }

    &.app-navigation-link {

      &,
      &:hover {
        color: hexToRGBA($primary-black-color, 30%);
      }

      &::after {
        bottom: calc(50% - 8px);
        right: 20px;
      }
    }

    &:hover {
      * {
        filter: inherit;
      }
    }

    img {
      opacity: 50%;
    }

    &,
    * {
      cursor: not-allowed;
    }

    &::after {
      display: block;
      position: absolute;
      bottom: -4px;
      right: -5px;
      height: 16px;
      width: 12px;
      content: '';
      background-color: $secondary-red-color;
      mask: url('./assets/icons/Access\ level.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      -webkit-mask: url('./assets/icons/Access\ level.svg');
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
    }
  }
}

@media (max-width: $menuChangedPx) {
  .app {
    flex-direction: column;

    .app-navigation-mobile {
      background-color: transparent;
      height: $mobileToolbarHeight;
      display: flex;
      align-items: center;

      img {
        height: 26px;
        margin-left: 9px;
      }
    }

    .app-table {

      .app-table-header-row,
      .app-table-content-row {
        font-size: 13pt;
      }
    }

    .app-navigation-visible-state:not(:checked) + .app-navigation-desktop-container {
      display: none;
    }

    .app-navigation-visible-state:checked + .app-navigation-desktop-container {
      display: flex;
    }

    .app-navigation-desktop-container {
      position: fixed;
      height: auto;
      top: 0;
      bottom: 0;
      z-index: $zindex-popover;
      background: $primary-white-color;
      max-width: 264px;
      min-width: 264px;
      box-shadow: 0 3px 6px #00000029;

      .app-navigation-close-wrapper {
        display: flex;
      }

      .app-navigation-close {
        padding: 8px 10px;
        cursor: pointer;
        background: $primary-white-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .app-navigation-overlay {
        background: #888888;
        opacity: 0.6;
        position: fixed;
        height: auto;
        right: 0;
        width: calc(100% - 264px);
        top: 0;
        bottom: 0;
        display: block;
        z-index: $zindex-fixed;
      }

    }

    .app-toolbar-breadcrumbs {
      display: none;
    }

    .app-toolbar-breadcrumbs-mobile {
      display: flex;
      margin: 1px 10px;

      span {
        margin-right: 9px;
      }

      img {
        height: 8px;
        margin: auto 9px auto 0;
      }

      .app-toolbar-breadcrumb {
        @include bold13;
        letter-spacing: 0;
        color: $primary-black-color;
        text-transform: uppercase;
        display: flex;
        font-size: 13px;
        font-weight: bolder;
        white-space: nowrap;
      }

      .app-toolbar-breadcrumb-separator {
        @include bold13;
        color: $primary-green-color;

        &.app-rotate-svg-180 svg {
          transform: rotate(180deg);
          transform-origin: center;
          height: 8px;
        }
      }
    }

    .app-mw-500 {
      max-width: 300px;
    }

    .app-portal {
      width: 100%;
    }

    .app-content {
      padding: 16px;

      @media (max-width: $zoomApplied) {
        zoom: 50%;
      }

      .app-systems-table-header {
        margin-bottom: 23px;
      }

      .app-search {
        margin-right: 10px;
      }
    }

    .app-content-footer-pagination {
      justify-content: flex-start;
    }
  }

  .app-content-toolbar {
    padding-left: 16px;
    padding-right: 16px;
    height: $mobileToolbarHeight;
    min-height: $mobileToolbarHeight;

    .app-toolbar-breadcrumbs + div {
      position: relative;
    }

    .app-toolbar-breadcrumb {
      font-size: 16px;
      white-space: nowrap;
    }

    img[alt=back] {
      height: 13px;
    }

    .app-toolbar-breadcrumb-separator {
      display: inline-block;
      height: 42px;

      svg {
        height: 13px;
      }
    }
  }

  .app-system-details-papers {
    display: flex;
    flex-direction: column;
  }

  .app-mobile-form {
    flex-direction: column;
    padding: 0 16px;
    max-width: 100%;

    .app-form-section-title {
      font-size: 12pt;
    }

    .app-w-521 {
      width: unset;
    }

    .app-mr-auto {
      margin-right: unset;
    }

    .app-pr-50 {
      padding-right: unset;
    }

    .app-form-input-postfix-switch {
      right: 65px !important;
    }

    .app-form-control .app-form-control-input .app-post-input-content {
      position: unset;
      top: unset;
      right: unset;
    }
  }
}

@media(max-width: 1227px) {
  .app-systems-hardware-header {
    flex-wrap: wrap;
    margin-bottom: 23px;

    .app-search {
      margin-bottom: 10px;
      width: 100%;
    }

    .app-search + div {
      justify-content: flex-end;
      width: 100%;
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .app-table-content-cell {
    display: block;
  }
  .app-table-content-cell-mobile {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .app-table-content-cell {
    display: none;
  }
  .app-table-content-cell-mobile {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .app-systems-table-header {
    flex-direction: column-reverse;

    a {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
    }

    .app-search {
      width: 100%;
      margin-left: 8px;
    }
  }
}
