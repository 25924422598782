@import '../../../variables';

.app-error-page {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: $zindex-popover;
  left: 0;
  width: 100%;
  background-color: white;

  display: flex;
  flex-direction: column;

  .app-error-page-background-container{
    background-image: url('../../../assets/Error message graphic.svg');
    opacity: 0.32;
    position: absolute;
    z-index: 1071;
    height: 100%;
    width: 100%;
  }
  .app-error-page-logo img {
    padding: 45px 68px;
    height: 42px;
  }
  .app-error-page-container{
    display: flex;
    margin: auto 0;
    z-index: 1072;


    .app-error-page-graphic svg{
      margin: auto;
      display: block;
    }
  }
  .app-error-page-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .app-error-page-title1,
    .app-error-page-title2,
    .app-error-page-title3 {
      letter-spacing: 0;
      color: $primary-black-color;
    }

    .app-error-page-title1 {
      font: normal normal 600 100px/140px 'myriad-pro';
      margin-bottom: 43px;
    }

    .app-error-page-title2 {
      font: normal normal 600 40px/56px 'myriad-pro';
      text-transform: uppercase;
    }

    .app-error-page-title3 {
      font: normal normal normal 22px/31px 'myriad-pro';
      margin-bottom: 65px;
    }
  }
}

@media(max-width: 869px) {
  .app-error-page-graphic {
    display: none;
  }
}

@media(max-width: 355px) {
  .app-error-page-logo {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .app-error-page-logo img {
    padding: 45px 68px;
  }
}
