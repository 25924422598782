@import './variables.scss';

.app-account-status-warning {
  display: flex;
  color: $primary-white-color;
  background: #DF676B;
  justify-content: center;
  font-size: 16px;
  padding: 15px;
  padding-bottom: 10px;

  &.hidden {
    display: none;
  }
  
  &.blocked {
    background: $secondary-red-color;
  }
}